//statistic.js
import request from '@/utils/request'
import qs from 'qs'


// 修改密码
export function changeQKpassword(id, password_old, password_new) {
    let data = {
        id, password_old, password_new
    }
    data = qs.stringify(data)
    return request({
        url: '/qikan/ModifyPassword',
        method: 'post',
        data
    })
}

// 查询期刊信息
export function searchqk(id) {
    let data = {
        id
    }
    data = qs.stringify(data)
    return request({
        url: '/qikan/QueryQiKan',
        method: 'post',
        data
    })
}

// 绑定手机号
export function bindqktel(phonecode, phone, id) {
    let data = {
        phonecode, phone, id
    }
    data = qs.stringify(data)
    return request({
        url: '/qikan/qkAddPhone',
        method: 'post',
        data
    })
}

//加急开通申请
export function isUrgent(id) {
    let data = {
        id
    }
    data = qs.stringify(data)
    return request({
        url: '/urgent/isUrgent',
        method: 'post',
        data
    })

}
//加急开通申请
export function applyUrgent(id) {
    let data = {
        id
    }
    data = qs.stringify(data)
    return request({
        url: '/urgent/applyUrgent',
        method: 'post',
        data
    })

}

//设置加急日期
export function updateUrgentDate(id,urgentDate) {
    let data = {
        id,urgentDate
    }
    data = qs.stringify(data)
    return request({
        url: '/urgent/updateUrgentDate',
        method: 'post',
        data
    })

}

