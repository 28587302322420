<template>
	<div>
		<div class="qkxx_title">
			修改资料
		</div>
		<div class="qkmyform">
			<el-form label-width="80px" :model="ruleForm2" :rules="rules2" ref="ruleForm2">
				<el-form-item label="修改头像">
				  <label for="headImg">
				    <img class="photo" :src="ruleForm2.headImgUrl" alt style="cursor: pointer;"/>
				  </label>
				  <input
				    class="file"
				    style="display: none"
				    id="headImg"
				    name="multipartFile"
				    type="file"
					 @change="up1"
				  />
				</el-form-item>
				<el-form-item label="原密码" prop="password" style="width: 40%;" >
				  <el-input v-model="ruleForm2.password" type="password" placeholder="请输入原密码" ></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="newpassword" style="width: 40%;" >
				  <el-input v-model="ruleForm2.newpassword" type="password" placeholder="请输入新密码" ></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="checkpassword" style="width: 40%;" >
				  <el-input v-model="ruleForm2.checkpassword" type="password" placeholder="请输入确认密码" ></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="danger"  @click="ChangePassword('ruleForm2')">保存</el-button>
				</el-form-item>
				</el-form>
		</div>
	</div>
</template>

<script>
	import addImg from "../../../assets/image/upImg.jpg";
	import {changeQKpassword, searchqk} from "../../../API/QKD/setting.js"
	export default {
		data() {
			return {
				pwd:"",
				ruleForm2: {
					headImgUrl: addImg, // 回显地址
					imageUrl: '',//头像url
					password:'',
					newpassword:'',
					checkpassword:''  
				},
				rules2: {
				   
				    password: [
				      { required: true, message: '请输入密码', trigger: 'blur' }
				    ],
				    newpassword: [
				      { required: true, message: '请输入新密码', trigger: 'blur' }
				    ],
				    checkpassword: [
				      { required: true, message: '请输入确认密码', trigger: 'blur' }
				    ]
				  }
				}
			
		},
		methods: {
		checkpass(){//验证新密码和二次密码
			if(this.ruleForm2.newpassword!==this.ruleForm2.checkpassword){
				 this.$message({
				          message: '两次密码不一致',
				          type: 'warning'
				        });
						return false
			}else{
				
				return true
				
			}
		},  up1 (e) {//上传头像方法
		  //头像
		  this.ruleForm2.headImg = e.target.files[0]; //图片文件
		  console.log( this.ruleForm2.headImg)
		  this.ruleForm2.headImgUrl = URL.createObjectURL(e.target.files[0]); //图片路径回显
		  var formdata=new FormData();
		  formdata.append('id',localStorage.getItem('QKId'));
		  console.log(localStorage.getItem('QKId'))
		  formdata.append('file', this.ruleForm2.headImg);
		  console.log(this.ruleForm2.headImg)
		  this.$axios({
			  method:'post',
			  url:"/qikan/modifyQKImg",
			  data:formdata,
			  headers:{
				 'Content-Type': 'multipart/form-data'  
			  }
		  }).then(res=>{
			 console.log(res)
			 if(res.data.code==0){
				 this.$message.success("头像修改成功")
			 }
			this.searchqkxx()
			  this.$root.$emit('next') //调用父路由中监听的next方法,然后传两个参
		  })
		  
		},//修改服务商密码
	ChangePassword(ruleForms){
		this.$refs[ruleForms].validate((valid, obj) => {
		  if (valid) {
			let resp=this.checkpass();
			if(resp){
				let id=localStorage.getItem('QKId');
			 changeQKpassword(id,this.ruleForm2.password,this.ruleForm2.newpassword).then(res=>{
				 console.log(res)
				 if(res.data.code==-1){
					 this.$message.error(res.data.msg)
				 }else if(res.data.code==0){
					 this.$message.success("修改成功")
				 }
			 })
			}
		}})
		
	},//查询期刊信息
		searchqkxx(){
			 searchqk(localStorage.getItem('QKId')).then(res=>{
				 console.log(res)
				 if(res.data.data.headimg.length!=0){
				 		this.ruleForm2.headImgUrl=this.URL+res.data.data.headimg
				 }
			 })
		}
	},mounted(){
		this.searchqkxx()
	}
}
	
</script>

<style>
.qkmyform{
	padding: 20px;
}
.photo{
	width: 100px;
}
</style>